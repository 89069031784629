import { getRequest } from '@/api/axios'

// 获取栏目信息
export const getNavigationList = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/column-list', params)
}

// 根据栏目信息获取数据
export const getAgricultureId = (params) => {
  return getRequest('/blade-integrate/openapi/integrateinformation/list', params)
}

// 根据栏目id拿所属标签
export const getLabeil = (params) => {
  return getRequest('/blade-integrate/openapi/integratelabelinfo/labelListByColumnId', params)
}
