import { getRequest } from '@/api/axios'

// 获取农村金融栏目列表目录
export const getRuralFinanceList = (params) => {
  return getRequest('/blade-integrate/openapi/integratelabelinfo/labelListByColumnId', params)
}
// 获取金融机构栏目列表目录的内容
export const getOrganizationContent = (params) => {
  return getRequest('/blade-integrate/openapi/integratebankinfo/list', params)
}
