<template>
  <div class="container">
    <!-- 农村金融 -->
    <div class="flex">
      <div class="left">
        <h1>农村金融</h1>
        <ul>
          <li
            v-for="(item, idx) in title"
            :key="item.id"
            :class="index == idx ? 'li_active' : ''"
            @click="son(item, idx)"
          >
            {{ item.labelName }}
          </li>
        </ul>
      </div>
      <div class="right">
        <div>
          <h1>{{ labelName }}</h1>
        </div>
        <div :class="index == 0 ? '' : 'no'">
          <ul class="flex">
            <li
              v-for="(item, idx) in list"
              :key="idx"
              class="bank_item rightbox"
            >
              <div class="bank_icon_wrap">
                <img :src="item.bankIcon" alt="" class="bank_icon" />
              </div>
              <div class="bank_name">{{ item.bankName }}</div>
              <ul class="text_ul">
                <li>
                  机构名称：<span>{{ item.bankName }}</span>
                </li>
                <li>
                  机构性质：<span>{{ item.bankType }}</span>
                </li>
                <li>
                  服务范围：<span>{{ item.serviceRange }}</span>
                </li>
                <li>
                  服务区域：<span>{{ item.serviceArea }}</span>
                </li>
                <li>
                  机构地址：<span>{{ item.address }}</span>
                </li>
                <li>
                  联系电话：<span>{{ item.phone }}</span>
                </li>
                <li>
                  注册资本：<span>{{ item.registeredCapital }}</span>
                </li>
                <li>
                  法定代表人：<span>{{ item.legalPerson }}</span>
                </li>
                <li>
                  简介：<span>{{ item.info }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div :class="index == 1 ? '' : 'no'">
          <ul>
            <li class="flex" v-for="(item, idx) in list1" :key="idx">
              <div>
                <div>
                  <img :src="item.bankIcon" alt="" />
                </div>
                <!-- <div>{{item.bankName}}</div> -->
              </div>
              <div>
                <div>{{ item.bankName }}</div>
                <ul>
                  <li>
                    服务类型：<span>{{ item.bankType }}</span>
                  </li>
                  <li>
                    服务对象：<span>{{ item.serviceRange }}</span>
                  </li>
                  <li>
                    服务期限：<span>{{ item.period }}</span>
                  </li>
                  <li>
                    服务内容：<span>{{ item.content }}</span>
                  </li>
                  <li>
                    服务简介：<span>{{ item.info }}</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div :class="index == 2 ? '' : 'no'">
          <ul>
            <li class="flex" v-for="(item, idx) in list2" :key="idx">
              <div>
                <div>
                  <img :src="item.bankIcon" alt="" />
                </div>
                <!-- <div>{{item.bankName}}</div> -->
              </div>
              <div>
                <div>{{ item.bankName }}</div>
                <ul>
                  <li>
                    产品性质：<span>{{ item.bankType }}</span>
                  </li>
                  <li>
                    价格：<span>{{ item.registeredCapital }}</span>
                  </li>
                  <li>
                    服务对象：<span>{{ item.serviceRange }}</span>
                  </li>
                  <li>
                    产品周期：<span>{{ item.period }}</span>
                  </li>
                  <li>
                    上市日期：<span>{{ item.listingDate }}</span>
                  </li>
                  <li>
                    退市日期：<span>{{ item.delistingDate }}</span>
                  </li>
                  <li>
                    产品简介：<span>{{ item.info }}</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRuralFinanceList,
  getOrganizationContent
} from "@/api/ruralFinance";
import { getNavigationList } from "@/api/about";

export default {
  name: "ruralFinance",
  data() {
    return {
      title: [],
      index: 0,
      list: "",
      list1: "",
      list2: "",
      labelName: "",
      bustype: 1
    };
  },
  methods: {
    son(item, idx) {
      this.bustype = idx * 1 + 1;
      this.index = idx;
      this.labelName = item.labelName;
      this.getorganizationContent(this.bustype);
    },
    async getAgtID() {
      await getNavigationList({
        parentId: 0
      }).then(res => {
        let columnId;
        res.data.forEach(element => {
          if (element.columnName == "农村金融") {
            columnId = element.id;
          }
        });
        this.getCatalogueList(columnId);
      });
    },
    // 获取标签名称
    async getCatalogueList(inforId) {
      const columnId = inforId;
      const _this = this;
      await getRuralFinanceList({ columnId }).then(res => {
        if (res.data) {
          res.data[0].labelName = "金融机构";
          res.data[2].labelName = "金融产品";
          _this.title = res.data;
          //   console.log(333333, this.title[_this.bustype - 1].labelName);
          this.labelName = this.title[_this.bustype - 1].labelName;
        }
      });
    },
    // 获取金融机构标签内容
    async getorganizationContent(bustype) {
      if (bustype == undefined) {
        bustype = 1;
      }
      await getOrganizationContent({ bustype }).then(res => {
        if (this.index == 0) {
          this.list = res.data.records;
        } else if (this.index == 1) {
          this.list1 = res.data.records;
        } else if (this.index == 2) {
          this.list2 = res.data.records;
        }
      });
    }
  },
  created() {
    this.bustype = this.$route.query.bustype;
  },
  mounted() {
    if (this.bustype == undefined) {
      this.index = 0;
    } else {
      this.index = this.bustype - 1;
    }
    this.getAgtID();
    this.getorganizationContent(this.bustype);
  }
};
</script>
<style scoped>
@import "../../assets/css/base.css";

.no {
  display: none;
}
.container {
  padding-top: 20px;
  margin-top: 10px;
}
.left {
  width: 300px;
  text-align: left;
  padding: 20px;
  box-shadow: 10px -7px 12px -6px rgba(0, 0, 0, 0.1);
}
.right {
  width: 100%;
  margin-left: 15px;
  padding-left: 25px;
  text-align: left;
  padding-bottom: 30px;
}
.rightbox {
  padding: 20px 10px;
  box-shadow: 5px 5px 10px 0px rgba(221, 221, 221, 0.8);
}
.left h1 {
  font-size: 20px;
  color: #93b067;
  margin-bottom: 12px;
}
.left li {
  color: #666;
  padding: 10px 10px 10px 16px;
  font-size: 14px;
  margin-bottom: 10px;
}
.left .li_active {
  background-color: #c8e5b8;
  width: 100%;
  color: #666;
}
.right > div:nth-child(1) {
  padding: 20px 0;
  padding-top: 0;
  margin-bottom: 10px;
}
.right > div:nth-child(1) h1 {
  font-weight: normal;
  position: relative;
}
.right > div:nth-child(1) h1::before {
  content: "";
  width: 5px;
  height: 20px;
  display: inline-block;
  background: #93b067;
  margin-right: 15px;
}
.right > div:nth-child(2) ul {
  justify-content: space-between;
  flex-wrap: wrap;
}
.right > div:nth-child(2) li {
  margin-top: 20px;
}
.right > div:nth-child(2) li > div:nth-child(1) {
  width: 100%;
}
/*.right>div:nth-child(2) li>div:nth-child(1) img{
    width: 100%;
    height: 400px;
}*/
.right .bank_icon_wrap {
  text-align: center;
}
.right .bank_icon_wrap .bank_icon {
  height: 200px;
  max-width: 100%;
}
.right > div:nth-child(2) li ul {
  margin-left: 5px;
}

.right > div:nth-child(3) > ul > li,
.right > div:nth-child(4) > ul > li {
  width: 100%;
  font-size: 18px;
  margin-top: 12px;
}
.right > div:nth-child(3) > ul > li > div:nth-child(1),
.right > div:nth-child(4) > ul > li > div:nth-child(1) {
  width: 260px;
}
.right > div:nth-child(3) > ul > li > div:nth-child(1) > div:nth-child(1),
.right > div:nth-child(4) > ul > li > div:nth-child(1) > div:nth-child(1) {
  height: 280px;
}
.right > div:nth-child(3) > ul > li > div:nth-child(1) > div:nth-child(2),
.right > div:nth-child(4) > ul > li > div:nth-child(1) > div:nth-child(2) {
  width: 100%;
  background-color: #0c0;
  color: #fff;
  font-size: 35px;
  text-align: center;
  padding: 10px 0;
}
.right > div:nth-child(3) > ul > li > div:nth-child(2),
.right > div:nth-child(4) > ul > li > div:nth-child(2) {
  width: 600px;
  margin-left: 30px;
}
.right > div:nth-child(3) > ul > li > div:nth-child(2) > div,
.right > div:nth-child(4) > ul > li > div:nth-child(2) > div {
  width: 100%;
  background-color: #9c6;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  font-size: 22px;
}
.right > div:nth-child(3) > ul > li > div:nth-child(2) li,
.right > div:nth-child(4) > ul > li > div:nth-child(2) li {
  line-height: 40px;
}
.right > div:nth-child(3) img,
.right > div:nth-child(4) img {
  width: 260px;
  height: 280px;
}
.right > div:nth-child(3) img {
  height: 320px;
}
.right .bank_item {
  font-size: 16px;
  width: 46%;
  line-height: 24px;
  margin-bottom: 30px;
}
.right .bank_name {
  font-size: 20px;
  margin: 0;
  padding: 10px 0;
  background-color: #93b067;
  color: #fff;
  text-align: center;
  width: 100%;
  border-radius: 4px;
}
.right .bank_item .text_ul {
  margin-top: 20px;
}
.right .bank_item .text_ul li {
  margin-top: 10px;
}
</style>
